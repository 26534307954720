// extracted by mini-css-extract-plugin
export var container = "layout-module--container--eLBMS";
export var navBar = "layout-module--nav-bar--rVFta";
export var desktopNav = "layout-module--desktop-nav--KEC6b";
export var siteTitle = "layout-module--site-title--5N6mf";
export var navLinkItem = "layout-module--nav-link-item--pfCo2";
export var navLinkText = "layout-module--nav-link-text--ac2nV";
export var hamburger = "layout-module--hamburger--9HVvW";
export var content = "layout-module--content--K-wVB";
export var mobileNav = "layout-module--mobile-nav--bdOzt";
export var hamburgerLine = "layout-module--hamburgerLine--MiJA-";
export var desktopNavLinks = "layout-module--desktop-nav-links--x5qrb";
export var heading = "layout-module--heading--8VjLO";
export var particles = "layout-module--particles--G27VQ";